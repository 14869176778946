.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.site-title{
  position: absolute;
  top: 2vh;
  left:2vw;
  font-family: Staatliches;
  color: white;
}

.site-heading{
  position: absolute;
  top: 24vh;
  left: 15vw;
  color: white;
  font-family: Staatliches;
  font-size: 2.8vw;
}

.site-blurb{
  position: absolute;
  top: 34vh;
  left: 15vw;
  width: 30vw;
  color: white;
  text-align: left;
  font-family: Dosis-bold;
  font-size: 1.7vw;
}

.hero-img{
  position: absolute;
  width: 35vw;
  right: 10vw;
  top: 13vh;
}

.learn-more {
  border-radius: 8px;
  outline: none;
  font-family: Dosis-extrabold;
  box-shadow: 0px 0px 3px #565656;
  border: none;
  cursor: pointer;
  font-size: 1.5vw;
  align-items: center;
  justify-items: center;
  width: 15vw;
  height: 5vh;
  padding-top: 0.5vh;
  position: absolute;
  left: 15vw;
  top: 48vh;
  background-color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

a:visited, a:link{
  color: #234657;
}

.learn-more:hover {
  background: #5479F7;
  color: #FFF;
  border: 0px;
}

.more-info{
  display: flex;
  flex-direction: row;
  margin-top: -45vh;
  margin-bottom: 20vh;
  padding: 2rem;
}

.knowledge-web{
  width: 40vw;
  margin-left: 10vw;
}

.web-text{
  display: flex;
  flex-direction: column;
  width: 35vw;
  position: absolute;
  right: 12vw;
}

.web-title{
  color: #384850;
  font-family: Staatliches;
  font-size: 2.8vw;
  text-align: left;
  margin-bottom: 4vh;
}

.web-para{
  color: #384850;
  font-family: Dosis-bold;
  font-size: 1.5vw;
  text-align: left;
  line-height: 4vh;
}

.footer{
  height: 20vh;
  width: 100%;
  background-color: #384850;
}

.footer-text{
 padding: 7vh;
 font-family: Dosis-bold;
  font-size: 1.2vw;
  color: white;
}

.email-notifications{
  width: 35vw;
  height: 53vh;
  margin: 0 auto 0 auto;
  background-color: #234657;
  margin-bottom: 20vh;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-text{
  font-family: Staatliches;
  font-size: 42px;
  margin-top: 4vh;
  width: 25vw;
  color: white;
}

.email-input{
  border-radius: 8px;
  width: 20vw;
  height: 6vh;
  font-size: 1.1vw;
  border: none;
  padding-left: 40px;
  font-family: Dosis-medium;
  margin-top: 4rem;
}

.sign-up {
  border-radius: 8px;
  outline: none;
  font-family: Dosis-extrabold;
  box-shadow: 0px 0px 3px #565656;
  border: none;
  cursor: pointer;
  font-size: 24px;
  align-items: center;
  justify-items: center;
  width: 15vw;
  height: 6vh;
  color: #244658;
  transition: all 0.3s ease-out;
  margin-top: 8vh;
}

.sign-up:hover {
  background: #5479F7;
  color: #FFF;
  border: 0px;
}

div span:nth-child(1){
  font-size: 3.5vw;
}

div span:nth-child(2){
  font-size: 3vw;
}

div span:nth-child(3){
  font-size: 3.5vw;
}

div span:nth-child(4){
  font-size: 3vw;
}

@font-face {
  font-family: "Staatliches";
  src: url("./fonts/Staatliches-Regular.ttf");
}

@font-face {
  font-family: "Dosis-bold";
  src: url("./fonts/Dosis-Bold.ttf");
}

@font-face {
  font-family: "Dosis-extrabold";
  src: url("./fonts/Dosis-ExtraBold.ttf");
}

@font-face {
  font-family: "Dosis-medium";
  src: url("./fonts/Dosis-Medium.ttf");
}